<script setup>
import Content from '@/views/Layouts/Content.vue'
import Contact from '@/components/Contact/Contact.vue'
// import Contact from '@/views/Contacts/Contact.vue'
// import Edit from '@/components/Edit.vue'

import { reactive, ref, onMounted, watch, computed, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'

import { getFullName } from '@/utils'

const route = useRoute()
const router = useRouter()
const store = useStore()
const v = useVuelidate()

const contact = ref(store.getters['contact/GET_MODEL'])

onMounted(async () => {
  contact.value = await store.dispatch('contact/getItem', route.params.id)
})

// const init = async () => {
//   // contact.value = store.getters['contact/GET_ITEM']({ id: route.params.id })
//   contact.value = await store.dispatch('contact/getItem', route.params.id)
// } 

// init()

// onMounted(() => {
   
//   // console.log(contact.value)
//   watch(() => formContact.value.v$, (val) => {
//     v$.value = val
//   })
// })

const updateItem = () => {
  console.log('updateItem')
  store.dispatch('contact/updateItem', {
    id: route.params.id,
    payload: contact.value
  }).then(() => {
    router.push('/contacts')
  })
}

const deleteItem = () => {
  console.log('deleteItem')
  store.dispatch('contact/deleteItem', {
    id: route.params.id
  }).then(() => {
    router.push('/contacts')
  })
}

</script>
<template>
  <div>
    <Content>
      <template #header-controls>
        <div>
          <button @click="deleteItem">Delete</button>
        </div>
      </template>
      <template #title>
        <div class="">
          <h3 class=""><span v-if="contact">{{ getFullName(contact) }}</span></h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">Информация о контакте</p>
        </div>
      </template>
      <template #content>
        <div class="mx-auto md:w-1/2">
          <Contact v-model="contact" />
          <div class="mt-5">
            <button class="btn btn-primary" :disabled="v.$silentErrors.length != 0"
              @click="updateItem">Обновить</button>
          </div>
        </div>
      </template>
    </Content>
  </div>
</template>